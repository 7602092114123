import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getIndicacaoLink() {
      return axios.get('/indicacao/link')
    },

    getEstatistica() {
      return axios.get('/indicacao/estatistica')
    },

  },
}
