<template>
  <div>
    <section id="dashboard-analytics">
      <b-row class="match-height">
        <b-col
          xl="4"
          md="6"
        >
          <b-card
            text-variant="center"
            class="card card-congratulations"
          >
            <!-- images -->
            <b-img
              :src="require('@/assets/images/elements/decore-left.png')"
              class="congratulations-img-left"
            />
            <b-img
              :src="require('@/assets/images/elements/decore-right.png')"
              class="congratulations-img-right"
            />
            <!--/ images -->

            <b-avatar
              variant="primary"
              size="70"
              class="shadow mb-2"
            >
              <feather-icon
                size="28"
                icon="AwardIcon"
              />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              {{ $t('indicacao.titleCardCongratulations') }}
            </h1>
            <b-card-text class="m-auto">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                size="sm"
                @click="copyLink"
              >
                {{ indicacaoLink }}

                <feather-icon
                  icon="CopyIcon"
                />
              </b-button>
            </b-card-text>
          </b-card>

        </b-col>
        <b-col
          xl="8"
          md="6"
        >
          <b-card
            no-body
            class="card-statistics"
          >
            <b-card-header>
              <b-card-title>{{ $t('indicacao.titleCardStatistics') }}</b-card-title>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-row v-if="estatistica">
                <b-col
                  xl="3"
                  sm="6"
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-success"
                      >
                        <feather-icon
                          size="24"
                          icon="UserIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ estatistica.cadastroAtivo }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t('indicacao.cardTextAtivos') }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  xl="3"
                  sm="6"
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-info"
                      >
                        <feather-icon
                          size="24"
                          icon="UserIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ estatistica.cadastroPendente }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t('indicacao.cardTextPendentes') }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  v-show="false"
                  xl="3"
                  sm="6"
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-danger"
                      >
                        <feather-icon
                          size="24"
                          icon="UserIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ estatistica.cadastroVencido }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t('indicacao.cardTextVencidos') }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardHeader, BCardTitle, BImg, BRow, BCol, BAvatar, BButton, BMedia, BMediaBody, BMediaAside,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import Ripple from 'vue-ripple-directive'
import indicacaoStoreModule from './indicacaoStoreModule'

const INDICACAO_STORE_MODULE_NAME = 'indicacao'

export default {

  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardHeader,
    BCardTitle,
    BImg,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BMedia,
    BMediaBody,
    BMediaAside,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      indicacaoLink: null,
      estatistica: null,
    }
  },

  computed: {
  },

  created() {
    if (!store.hasModule(INDICACAO_STORE_MODULE_NAME)) store.registerModule(INDICACAO_STORE_MODULE_NAME, indicacaoStoreModule)
    this.getIndicacaoLink()
    this.getEstatistica()
  },

  beforeDestroy() {
    if (store.hasModule(INDICACAO_STORE_MODULE_NAME)) store.unregisterModule(INDICACAO_STORE_MODULE_NAME)
  },

  methods: {

    getEstatistica() {
      store
        .dispatch('indicacao/getEstatistica')
        .then(response => {
          this.estatistica = response.data
        })
    },

    getIndicacaoLink() {
      store
        .dispatch('indicacao/getIndicacaoLink')
        .then(response => {
          this.indicacaoLink = response.data
        })
    },

    copyLink() {
      const linkText = this.indicacaoLink

      // Cria um elemento de input temporário
      const input = document.createElement('input')
      input.style.opacity = 0
      input.value = linkText
      document.body.appendChild(input)

      // Seleciona o texto no input
      input.select()
      input.setSelectionRange(0, linkText.length)

      // Executa o comando de cópia
      document.execCommand('copy')

      // Remove o input temporário
      document.body.removeChild(input)

      // Exibe uma mensagem ou feedback de sucesso
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link copiado!',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
